








import { Component, Vue } from 'vue-property-decorator'

import { ProfileWrapper } from '../../profile/shared'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Favourite>({
  name: 'Favourite',
  components: { ProfileWrapper }
})
export class Favourite extends Vue {

}

export default Favourite
